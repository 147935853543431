import { useState } from "react";
import "./App.css";
import Footer from "./Components/Footer/Footer";
import SideBar from "./Components/Sidebar/SideBar";
import Navbar from "./Components/NavBar/Navbar";
import Aboutus from "./Components/Pages/Aboutus";
import HomePage from "./Components/Pages/Home";
import BackgroundImage from "./Components/background"; 

function App() {
  const [currentPage, setCurrentPage] = useState("Home");

  return (
    <div className="app">
      <BackgroundImage />
      <Navbar setCurrentPage={setCurrentPage} />
      <SideBar />
      {currentPage === "Home" && <HomePage />}
      {currentPage === "Aboutus" && <Aboutus />}
      <Footer />
    </div>
  );
}

export default App;
