import { useState } from "react";
import PropTypes from "prop-types";
import "./Navbar.scss";
import { MdMenu, MdClose } from "react-icons/md";
import SideBar from "../Sidebar/SideBar";

const Navbar = ({ setCurrentPage }) => {
  const [isMobileView, setIsMobileView] = useState(false);
  const [isSideBarOpen, setSideBarOpen] = useState(false);
  return (
    <div className="NavBar">
      <button
        className="hamburger-icon"
        onClick={() => {
          setIsMobileView(!isMobileView);
          setSideBarOpen(!isSideBarOpen); // toggle the visibility of SideBar when the hamburger button is clicked
        }}
      >
        {isMobileView ? <MdClose /> : <MdMenu />}
      </button>
      <div className={`nav ${isMobileView ? "show" : ""}`}>
        <ul>
          <li>
            <button
              className="nav-items"
              onClick={() => setCurrentPage("Home")}
            >
              Home
            </button>
          </li>
          <li>
            <button
              className="nav-items"
              onClick={() => setCurrentPage("Aboutus")}
            >
              About
            </button>
          </li>
          <li>
            <button className="nav-items" onClick={() => {}}>
              Services{" "}
            </button>
          </li>
          <li>
            <button className="nav-items" onClick={() => {}}>
              Blog{" "}
            </button>
          </li>
          <li>
            <button className="nav-items" onClick={() => {}}>
              Contact Us{" "}
            </button>
          </li>
        </ul>

        <div className="language-switch">
          <button>EN</button>
          <button>AR</button>
        </div>
      </div>
      <SideBar isOpen={isSideBarOpen} />
    </div>
  );
};
Navbar.propTypes = {
  setCurrentPage: PropTypes.func.isRequired,
};
export default Navbar;
