import "./SideBar.scss";
import "@fortawesome/fontawesome-free/css/all.min.css";
import LogoSide from "../../assets/Logo.webp";
import PropTypes from "prop-types";

const SideBar = ({ isOpen }) => {
  return (
    <div className={`sidebar ${isOpen ? "open" : ""}`}>
      <img src={LogoSide} alt="Logo" className="logo" />
      <ul className="social-media">
        <li>
          <a
            href="https://www.facebook.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="icon">
              <i className="fab fa-facebook-f"></i>
            </div>
          </a>
        </li>
        <li>
          <a
            href="https://www.twitter.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="icon">
              <i className="fab fa-x-twitter"></i>
            </div>
          </a>
        </li>
        <li>
          <a
            href="https://www.linkedin.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="icon">
              <i className="fab fa-linkedin-in"></i>
            </div>
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="icon">
              <i className="fab fa-instagram"></i>
            </div>
          </a>
        </li>
        <li>
          <a
            href="https://www.youtube.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="icon">
              <i className="fab fa-youtube"></i>
            </div>
          </a>
        </li>
        <li>
          <a href="https://wa.me" target="_blank" rel="noopener noreferrer">
            <div className="icon">
              <i className="fab fa-whatsapp"></i>
            </div>
          </a>
        </li>
        <li>
          <a
            href="https://telegram.me"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="icon">
              <i className="fab fa-telegram-plane"></i>
            </div>
          </a>
        </li>
      </ul>
    </div>
  );
};
SideBar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};

export default SideBar;
