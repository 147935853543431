import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";
import LogoBig from "../../assets/LogoBig.webp";
import Service1Image from "../../assets/Service1Image.webp";
import Service2Image from "../../assets/Service2Image.webp";
import Avatar from "react-avatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQuoteLeft,
  faBullseye,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
const HomePage = () => {
  const [status, setStatus] = useState("");
  const [fullName, setFullName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(
        "https://babilagency.com/scripts/contact.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams({
            fullName,
            emailAddress,
            subject,
            message,
          }),
        }
      );

      const result = await response.text();
      setStatus(result);
    } catch (error) {
      setStatus(
        "There was an error sending your message. Please try again later."
      );
    }
  };
  const [inProp, setInProp] = useState(false);

  useEffect(() => {
    setInProp(true);
  }, []);

  const TestimonialCard = ({ name, content }) => (
    <div className="testimonial-card p-6 bg-white shadow-lg rounded-lg transition-all duration-300 hover:shadow-xl">
      <FontAwesomeIcon
        icon={faQuoteLeft}
        className="text-3xl text-blue-500 mb-4"
      />
      <p className="mb-6 text-gray-700 italic leading-relaxed">{content}</p>
      <div className="flex items-center">
        <Avatar name={name} size="48" round={true} className="mr-4" />
        <h3 className="text-lg font-semibold text-gray-800">{name}</h3>
      </div>
    </div>
  );
  TestimonialCard.propTypes = {
    name: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
  };
  const TeamMember = ({ name, role }) => (
    <div className="team-member bg-white p-6 rounded-lg shadow-md transition-all duration-300 hover:shadow-xl">
      <Avatar name={name} size="80" round={true} className="mx-auto mb-4" />
      <h4 className="text-center text-xl font-semibold text-gray-800">
        {name}
      </h4>
      <p className="text-center text-sm text-gray-600">{role}</p>
    </div>
  );
  TeamMember.propTypes = {
    name: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
  };
  const ServiceCard = ({ title, image, description }) => (
    <div className="service-card flex flex-col items-center p-6 bg-white shadow-lg transform transition-transform duration-300 ease-in-out hover:scale-105 hover:-translate-y-1 rounded-lg">
      <h3 className="text-xl font-semibold mb-3 text-gray-800">{title}</h3>
      <img
        src={image}
        alt={title}
        className="mb-4 w-24 h-24 object-cover rounded-full"
      />
      <p className="text-center text-gray-600">{description}</p>
    </div>
  );
  ServiceCard.propTypes = {
    title: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  };

  const testimonials = [
    {
      name: "James Smith",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      name: "Jennifer Brown",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      name: "Linda Davis",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      name: "Robert Johnson",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      name: "Patricia Miller",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      name: "Michael Williams",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
  ];
  return (
    <CSSTransition in={inProp} timeout={500} classNames="page-transition">
      <>
        <section className="min-h-screen relative bg-cover bg-center flex flex-col md:flex-row items-center justify-between text-white px-4 py-20 md:py-0">
          <div className="w-full md:w-1/2 md:pl-8 lg:pl-16 z-10">
            <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold mb-4">
              Welcome
            </h1>
            <h2 className="text-2xl md:text-3xl lg:text-4xl mb-6">
              Tailored care, anywhere
            </h2>
            <p className="text-lg md:text-xl lg:text-2xl mb-8 max-w-2xl">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut sem
              nulla pharetra diam sit amet nisl suscipit
            </p>
            <button className="w-full md:w-auto bg-blue-500 text-white py-4 px-8 rounded-full text-lg font-semibold transition-all duration-300 ease-in-out hover:bg-blue-700 hover:scale-105">
              Book Your Appointment Now
            </button>
          </div>
          <div className="w-full md:w-1/2 flex justify-center md:justify-end items-center mt-8 md:mt-0">
            <img
              src={LogoBig}
              alt="Logo"
              className="w-48 md:w-64 lg:w-auto h-auto max-h-96"
            />
          </div>
        </section>

        <section className="px-4 py-16 bg-gray-100">
          <div className="max-w-6xl mx-auto">
            <h2 className="text-3xl md:text-4xl font-bold text-center mb-12 text-gray-800">
              Our Services
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
              <ServiceCard
                title="Telemedicine Service"
                image={Service1Image}
                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
              />
              <ServiceCard
                title="Home Healthcare Service"
                image={Service2Image}
                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
              />
              <ServiceCard
                title="Telemedicine Service"
                image={Service1Image}
                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
              />
              <ServiceCard
                title="Home Healthcare Service"
                image={Service2Image}
                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
              />
            </div>
          </div>
        </section>
        <section className="testimonials py-20 bg-gray-100">
          <div className="container mx-auto px-4">
            <h2 className="text-center text-4xl font-bold mb-12 text-gray-800">
              What Our Customers Say
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
              {testimonials.map((testimonial, index) => (
                <TestimonialCard key={index} {...testimonial} />
              ))}
            </div>
          </div>
        </section>
        <section className="about-section py-20 bg-gray-100">
          <div className="container mx-auto px-4">
            <h2 className="text-center text-5xl font-bold mb-16 text-gray-800">
              About Us
            </h2>

            <div className="mb-20">
              <div className="flex items-center justify-center mb-8">
                <FontAwesomeIcon
                  icon={faBullseye}
                  className="text-4xl text-blue-500 mr-4"
                />
                <h3 className="text-3xl font-semibold text-gray-800">
                  Our Mission
                </h3>
              </div>
              <p className="text-lg text-gray-700 mb-8 max-w-3xl mx-auto text-center leading-relaxed">
                At our healthcare company, we are dedicated to providing
                comprehensive and personalized care to our clients. Our mission
                is to ensure that everyone has access to the highest quality
                medical services, empowering them to lead healthier and happier
                lives.
              </p>
              <div className="text-center">
                <a
                  href="#"
                  className="inline-block bg-blue-500 text-white py-3 px-8 rounded-full text-lg font-semibold transition-all duration-300 ease-in-out hover:bg-blue-600 hover:shadow-lg"
                >
                  Learn More
                </a>
              </div>
            </div>

            <div>
              <div className="flex items-center justify-center mb-12">
                <FontAwesomeIcon
                  icon={faUsers}
                  className="text-4xl text-blue-500 mr-4"
                />
                <h3 className="text-3xl font-semibold text-gray-800">
                  Our Team
                </h3>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                <TeamMember name="John Doe" role="CEO" />
                <TeamMember name="Jane Smith" role="Medical Director" />
                <TeamMember name="Michael Johnson" role="Lead Physician" />
              </div>
            </div>
          </div>
        </section>
        <section className="contact-form m-20 mx-0">
          <form
            className="mx-auto w-full mt-12 lg:w-5/12"
            onSubmit={handleSubmit}
          >
            <div className="flex flex-col sm:flex-row mb-8 gap-5">
              <input
                className="border-2 border-gray-300 p-2 rounded-md w-full"
                placeholder="Full Name"
                id="fullName"
                aria-label="Full Name"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
              <input
                className="border-2 border-gray-300 p-2 rounded-md w-full"
                placeholder="Email Address"
                id="emailAddress"
                aria-label="Email Address"
                value={emailAddress}
                onChange={(e) => setEmailAddress(e.target.value)}
              />
            </div>
            <input
              className="border-2 border-gray-300 p-2 rounded-md w-full"
              placeholder="Subject"
              id="formsubject"
              aria-label="Subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
            <textarea
              className="border-2 border-gray-300 p-2 rounded-md w-full mt-8 resize-none"
              rows={8}
              placeholder="Message"
              id="message"
              aria-label="Message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <button
              type="submit"
              className="mt-8 w-full bg-gradient-to-r from-blue-400 to-cyan-600 text-white py-2 px-4 rounded"
            >
              Contact Us
            </button>
            <div className="status-message">{status}</div>
          </form>
        </section>
      </>
    </CSSTransition>
  );
};

export default HomePage;
