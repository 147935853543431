import "./Footer.scss";
import LogoFooter from "../../assets/Logo.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGooglePlay,
  faAppStoreIos,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="footer">
      <div className="top-section">
        <img src={LogoFooter} className="logo-footer" alt="Logo" />
        <div className="logo-download">
          <div className="download-footer">
            <div className="stores-footer">
              <a
                className="google-play-footer"
                href="https://play.google.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faGooglePlay} />
              </a>
              <a
                className="app-store-footer"
                href="https://www.apple.com/ios/app-store/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faAppStoreIos} />
              </a>
            </div>
          </div>
        </div>
        <div className="contact-us-footer">
          <h3>Contact us</h3>
          <p>Have any questions? Get in touch </p>
          <p>+9647777777777 </p>
          <p>contactus@site.iq</p>
        </div>
      </div>
      <hr />
      <div className="copyright">
        Copyright &copy; {currentYear}{" "}
        <a
          href="https://siliconsquire.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          SiliconSquire
        </a>
        . All rights reserved
      </div>
    </footer>
  );
};

export default Footer;
