import bgImage from "../assets/Main.webp";
import "./background.css";

const BackgroundImage = () => {
  return (
    <div
      className="background-image"
      style={{
        backgroundImage: `url(${bgImage})`,
      }}
    />
  );
};

export default BackgroundImage;
